import MenuToggle from './components/MenuToggle'

const idExists = document.querySelector('#menuToggle')
if (idExists) {
	const menuToggleApp = new window.Vue({
		el: '#menuToggle',
		components: {
			MenuToggle,
		},
		methods: {},
		mounted() {},
	})
}
