<template>
	<div v-click-outside="onClickOutside"  class="menuImg">
		<svg
			v-if='menuVisable'
			v-on:click="toggleMenu"
			class="w-8 lg:hidden"
			width="22"
			height="23"
			viewBox="0 0 22 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 1.5L21 21.5M21 1.5L1 21.5"
				stroke="black"
				stroke-width="2.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>

		<svg
			v-else
			v-on:click="toggleMenu"
			id='burger'
			class="w-8 lg:hidden"
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			stroke="currentColor"
		>
			<path
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M4 6h16M4 12h16M4 18h16"
			/>
		</svg>


		<div v-on:click="toggleMenu" id="menuDropDown" class="bg-white">
			<div>
				<ul>
					<li v-if="!targetedCampaign" class='list-item'>
						<a  v-if="showlogout"
							:href="logout"
							class="link font-poppins font-medium  hover:text-gray-500"
							>Logout</a
						>
						<a  v-else
							:href="portal"
							:class="{ 'disabled-link': isCurrentPage('/customer-portal/login') }"
							:style="{ pointerEvents: isCurrentPage('/customer-portal/login') ? 'none' : 'auto' }"
							class="link font-poppins font-medium hover:text-gray-500"
							>My Account</a
						>
					</li>
					<li class='list-item'>
						<a :href="personal"
							:class="{ 'disabled-link': isCurrentPage(personal) }"
							:style="{ pointerEvents: isCurrentPage(personal) ? 'none' : 'auto' }"
							class="link font-poppins font-medium hover:text-gray-500"
							>Breakdown Cover</a
						>
					</li>
					<li class='list-item'>
						<a
							:href="business"
						    :class="{ 'disabled-link': isCurrentPage(business) }"
							:style="{ pointerEvents: isCurrentPage(business) ? 'none' : 'auto' }"
							class="link font-poppins font-medium hover:text-gray-500"
							>Business Cover</a
						>
					</li>
					<li v-if="!targetedCampaign" class='list-item'>
						<a
							:href="contact"
							:class="{ 'disabled-link': isCurrentPage(contact) }"
							:style="{ pointerEvents: isCurrentPage(contact) ? 'none' : 'auto' }"
							class="link font-poppins font-medium hover:text-gray-500"
							>Contact Us</a
						>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import vOutsideEvents from 'vue-outside-events'

window.Vue.use(vOutsideEvents)

export default {
	name: 'MenuToggle',
	props: {
		personal: {
			type: String,
			default: '',
		},
		business: {
			type: String,
			default: '',
		},
		portal: {
			type: String,
			default: '',
		},
		contact: {
			type: String,
			default: '',
		},
		logout: {
			type: String,
			default: '',
		},
		showlogout: {
			type: Boolean,
			default: false,
		},
		targetedCampaign: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			menuVisable: false
		}
	},
	methods: {
		onClickOutside (e, el) {
			if(this.menuVisable) {
				this.toggleMenu()
			}
        },
		toggleMenu: function () {
			const menu = document.querySelector('#menuDropDown')
			menu.classList.toggle('show-menu')

			if(menu.classList.contains('show-menu')) {
				this.menuVisable = true
				window.addEventListener('scroll', this.hideOnScroll)
			} else {
				window.removeEventListener('scroll', this.hideOnScroll)
				this.menuVisable = false
			}
		},
		hideOnScroll: function (e) {
			const menu = document.querySelector('#menuDropDown')
				if(menu && menu.classList.contains('show-menu')){
					this.toggleMenu()
				}
		},
		isCurrentPage(url) {
			const currentPath = window.location.pathname;
			const urlPath = new URL(url, window.location.origin).pathname;
			return currentPath === urlPath;
		}
	},
}
</script>

<style scoped>
#menuDropDown {
	width: 100%;
	position: absolute;
	right: 0%;
	overflow-x: hidden;
	-webkit-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	transform: translateY(-100%);
	-webkit-transition: 1s;
	-o-transition: 1s;
	transition: 1s;
	text-align: center;
	line-height: 3rem;
	-webkit-box-shadow: -6px 12px 12px 2px rgba(0, 0, 0, 0.26);
	box-shadow: -6px 12px 12px 2px rgba(0, 0, 0, 0.26);
	z-index: -10;
}
#menuDropDown.show-menu {
	-webkit-transform: translateY(-0%);
	-ms-transform: translateY(-0%);
	transform: translateY(-0%);
	-webkit-transition: 1s;
	-o-transition: 1s;
	transition: 1s;
}
li {
	margin: 10% 0%;
}
@media only screen and (min-width: 700px) {
	#menuDropDown {
		width: 40%;
	}
}
</style>
